import React from 'react';
import ContactForm from './ContactForm';
import GoogleMapStyle from './GoogleMapStyle';
import {
  FiFacebook,
  FiHeadphones,
  FiInstagram,
  FiLinkedin,
  FiMail,
  FiMapPin,
  FiTwitter,
  FiYoutube,
} from 'react-icons/fi';
import contactData from '../../locales/contactData';

const ContactOne = () => {
  const {
    email,
    phoneNumber,
    address: { street, city },
  } = contactData;
  return (
    <>
      {/* Start Elements Area  */}
      <div className="rwt-social-area rn-section-gapBottom">
        <div className="container">
          <div className="row mt--30">
            <div className="col-lg-12">
              <ul className="social-icon social-default with-bg-primary">
                <li>
                  <a
                    href="https://www.facebook.com/hegecode"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FiFacebook />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.twitter.com/hegecode"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FiTwitter />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/hegecode"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FiInstagram />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/hegecode"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FiLinkedin />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/@hegecode"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FiYoutube />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* End Elements Area  */}

      <div className="row row--15">
        <div className="col-lg-12">
          <div className="rn-contact-address mt_dec--30">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-address">
                  <div className="icon">
                    <FiHeadphones />
                  </div>
                  <div className="inner">
                    <h4 className="title">Our Phone Number</h4>
                    <p>
                      <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
                    </p>
                    <p>
                      <br />
                    </p>
                    {/* <p><a href="tel:+222 222 222 333">+222 222 222 333</a></p> */}
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-address">
                  <div className="icon">
                    <FiMail />
                  </div>
                  <div className="inner">
                    <h4 className="title">Our Email Address</h4>
                    <p>
                      <a href={`mailto:${email}`}>{email}</a>
                      <p>
                        <br />
                      </p>
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-address">
                  <div className="icon">
                    <FiMapPin />
                  </div>
                  <div className="inner">
                    <h4 className="title">Our Location</h4>
                    <p>{street}</p>
                    <p>{city}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt--40 row--15">
        <div className="col-lg-7">
          <ContactForm formStyle="contact-form-1" />
        </div>
        <div className="col-lg-5 mt_md--30 mt_sm--30">
          <GoogleMapStyle />
        </div>
      </div>
    </>
  );
};
export default ContactOne;
