import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../breadcrumb/BreadcrumbOne';
import SectionTitle from '../sectionTitle/SectionTitle';
import ServicesScrollAnimation from './ServicesScrollAnimation';
import locales from '../../locales/locales';
import { slugify } from '../../utils';
import CustomServices from './CustomServices';
import ClientsBrand from '../brand/ClientsBrand';

const Service = ({
  match: {
    params: { slug },
  },
}) => {
  const {
    SERVICES_TITLE,
    SERVICES_HEADER_TITLE,
    SERVICES_HEADER_TEXT,
    SERVICES_ENUMERATION,
  } = locales;
  const data = SERVICES_ENUMERATION.map((industry) => {
    if (!industry.route) {
      return industry;
    }

    return {
      ...industry,
      expectedeIndustry: slugify(industry.route) === slug,
    };
  }).filter((industry) => industry.expectedeIndustry)[0];
  const industryTitle = data && data.title;

  if (industryTitle) {
    return <CustomServices data={data} />;
  }
  return (
    <>
      <SEO title="Services" />
      <Layout>
        <BreadcrumbOne
          title={SERVICES_TITLE}
          rootUrl="/"
          parentUrl="Home"
          currentUrl="Services"
        />
        <div className="main-content">
          {/* Start Service Area  */}
          <div className="rn-service-area rn-section-gap">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <SectionTitle
                    textAlign="text-center"
                    radiusRounded=""
                    subtitle=""
                    title={SERVICES_HEADER_TITLE}
                    description={SERVICES_HEADER_TEXT}
                  />
                </div>
              </div>
              <ServicesScrollAnimation
                serviceStyle="service__style--1 bg-color-blackest radius mt--25 rbt-border"
                textAlign="text-start"
              />
            </div>
          </div>
          {/* End Service Area  */}

          <div className="rwt-brand-area rn-section-gap">
            <ClientsBrand brandStyle="brand-style-2 justify-content-evenly" />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Service;
