import {
  FiBarChart,
  FiBriefcase,
  FiCloud,
  FiDatabase,
  FiDollarSign,
  FiEdit,
  FiEye,
  FiInfo,
  FiMonitor,
  FiMusic,
  FiShare2,
  FiShield,
  FiShoppingBag,
  FiSmartphone,
  FiStar,
  FiTool,
  FiTrendingUp,
} from 'react-icons/fi';

const locales = Object.seal({
  PRE_TITLE: 'INFORMATION TECHNOLOGY SOLUTIONS',
  TITLE: 'Building and accelerating innovation.',
  TITLE_ENUMERARION: [
    'Operational excellence.',
    'Security.',
    'Reliability.',
    'Performance efficiency.',
    'Cost optimization.',
    'Sustainability.',
  ],
  SUBTITLE: 'Creating software that defines your business.',
  INDUSTRIES_TEXT:
    'We offer software solutions for companies around the world for multiple industries, including Retail, Financial Services, Mobility, Automotive, Security, Healthcare, Marketing & Advertising, Recreational Facilities & Services and Law. Our goal is to implement and design high quality software solutions.',
  TEAM_TEXT:
    'Along with investing in long-term customer relationships, Hegecode admits the importance of providing professional strategies for the workload, incorporating operational excellence, security, reliability, performance efficiency, cost optimization and sustainability with our professional engineers.',

  // About page
  ABOUT_TITLE: 'Hegecode at a glance',
  ABOUT_HEADER_LEFT:
    'Hegecode is an IT company that accelerates innovation, enhancing the relation between people and technology.',
  ABOUT_HEADER_RIGHT:
    "We don't just complete projects, e collaborate closely with you to understand your difficulties, the market's expectations on your organization, and to design, create, operate, and maintain the secure, scalable platforms that position you as a market disruptor.",

  // Services page
  SERVICES_PRE_TITLE: 'SERVICES',
  SERVICES_TITLE: 'Our Services',
  SERVICES_TEXT:
    "We are committed to exceeding our customers' expectations by providing high-quality services. We achieve these objectives through the expertise of a qualified team capable of performing complex project analysis.",
  SERVICES_HEADER_TITLE:
    "A portfolio that is constantly evolving to meet our clients' ever-changing needs.",
  SERVICES_HEADER_TEXT:
    '<p>It is difficult to hire, establish, operate, and evolve teams. But there is one more option. Great engineers are hard to come by. As the pool shrinks, the cost of participation rises. We provide a versatile team system to assure a comprehensive, long-term, and cost-effective connection. Our one-of-a-kind solution advances alongside your business, with software professionals deployed to provide you with the correct bandwidth wherever it is required. Our teams have been working together for almost ten years and are well-oiled machines. We are quick to onboard and have the internal support to generate outcomes quickly.</p>',
  SERVICES_ENUMERATION: [
    {
      icon: <FiMonitor />,
      title: 'Software Development',
      shortText: `
        <ul>
          <li>Web Development</li>
          <li>Software Architecture</li>
          <li>Embedded Systems</li>
          <br/>
          <br/>
          <br/>
          <br/>
        <ul>
      `,
      route: 'software-development',
      overviewText: `
        <p>The goal is to adapt to the current technical environment conditions and provide the best technical solutions with the least amount of intervention.</p>
        <p>Our primary service is the creation of customised integrated software solutions that address our clients' increasingly precise and specialized demands. We work hard to ensure that our solutions are easily adaptable to changes in diverse domains of business. Our apps take use of the most recent technologies, distributed implementation, and remote control capabilities, allowing even a firm with several points of access, mobile or not, to benefit from the same application.</p>
      `,
      articles: [
        {
          image: '/images/services/responsive-web-design.jpg',
          articleTitle: 'Web Development',
          articleText: `
            <p>We have experience using multiple technologies, but mainly JavaScript, combined with HTML and CSS for the frontend, provides everything needed in order to build complex and user-friendly interfaces for our projects.</p>
            <p>For the frontend side, our engineers use technologies that are derivated from JavaScript, like React, Angular, VueJS with TypeScript, but not limited to these. We use them for applications that demand quick load times and minimum maintenance.</p>
            <p>Also for the backend we mainly focus on architecting and developting systems using NodeJS, Java, Python or .NET, but we also use PHP, with Laravel and some other languages and technologies.</p>
          `,
        },
        {
          image: '/images/services/software-architecture.jpg',
          articleTitle: 'Software Architecture',
          articleText: `
            <p>Good architecture is something that supports its own evolution, and is deeply intertwined with programming.</p>
            <p>Poor architecture is a major contributor to the growth of cruft - software components that make it difficult for engineers to comprehend the code and the product. Software with a lot of cruft is significantly more difficult to adapt, resulting in features that arrive more slowly and with more flaws.</p>
            <p>Our specialists have the ability to build software architectures that stimulates the ability of developers to understand the software.</p>
          `,
        },
        {
          image: '/images/services/embedded-systems.jpg',
          articleTitle: 'Embedded Systems',
          articleText: `
            <p>An embedded system is a set of computer hardware and software that is designed to perform a certain function. Embedded systems can also work as part of a larger system. The systems might be programmable or fixed in function.</p>
            <p>We have experience working with systems with complex graphical user interfaces and also with systems havin no user interface.</p>
          `,
        },
      ],
      brands: ["Wearin'", 'DFS', 'UNEA', 'Terminal X', 'Materom'],
    },
    {
      icon: <FiSmartphone />,
      title: 'Mobile Development',
      shortText: `
        <ul>
          <li>Android Development</li>
          <li>iOS Development</li>
          <li>PWA Development</li>
          <br/>
          <br/>
          <br/>
          <br/>
        <ul>`,
      route: 'mobile-development',
      overviewText:
        '<p>We tend to use cross-platform technologies, like React Native and Xamarin, but we also work with native technologies, including Kotlin, Objective-C or Swift</p>',
      // articles: [
      //   {
      //     image: '/images/services/android-development.jpg',
      //     articleTitle: 'Android Development',
      //     articleText: `
      //       <p>An embedded system is a set of computer hardware and software that is designed to perform a certain function. Embedded systems can also work as part of a larger system. The systems might be programmable or fixed in function.</p>
      //       <p>Lorem ipsum</p>
      //     `,
      //   },
      // ],
    },
    {
      icon: <FiDatabase />,
      title: 'DevOps Services',
      shortText: `
        <ul>
          <li>Cloud Automation</li>
          <li>Application Modernization</li>
          <li>Cloud Migration</li>
          <li>DevOps Managed Services</li>
          <li>Cloud & DevOps Strategy and Consulting</li>
        <ul>`,
      route: 'devops-services',
      overviewText:
        '<p>Do you want to save time and money by using DevOps concepts and services such as agility, automation, continuity, and governance? Hegecode can help you adopt DevOps best practices such as cloud integration, continuous integration and continuous delivery (CI/CD), GitOps, containerization, DevSecOps, AI/ML, and AI Ops, among other things. With Hegecode, a DevOps solution provider, you can automate and coordinate software development life cycle (SDLC) operations such as development, security, testing, and deployment.</p>',
      articles: [
        {
          image: '/images/services/cloud-automation.jpg',
          articleTitle: 'Cloud Automation',
          articleText: `
            <ul>
              <li>Infrastructure as Code (IaC)</li>
              <li>Configuration as Code (CaC)</li>
              <li>Infrastructure Management</li>
              <li>Observability</li>
              <li>Continuous Delivery (CD)</li>
              <li>Continuous Integration (CI)</li>
              <li>Highly Available & Elastic Setup</li>
            </ul>
          `,
        },
        {
          image: '/images/services/application-modernization.jpg',
          articleTitle: 'Application Modernization',
          articleText: `
            <ul>
              <li>Kubernetes
              <li>Application Containerization</li>
              <li>Cloud-Native Architecture</li>
              <li>Serverless Deployments</li>
              <li>Cloud Application Migration</li>
              <li>Pipelines Building and Deployment</li>
            </ul>
          `,
        },
        {
          image: '/images/services/cloud-migration.jpg',
          articleTitle: 'Cloud Migration',
          articleText: `
            <ul>
              <li>Cloud Migration Planning</li>
              <li>Migration Business Case</li>
              <li>Cloud Cost Forecasting</li>
              <li>Database Migration to Managed Services</li>
              <li>Cloud Optimization for Security, Performance and Cost</li>
              <li>Infrastructure Migration and Right-Sizing</li>
            </ul>
          `,
        },
        {
          image: '/images/services/devops-managed-services.jpg',
          articleTitle: 'DevOps Managed Services',
          articleText: `
            <p>DevOps is suitable for businesses that constantly alter their digital goods and want to do so in the least amount of time while maintaining high quality. DevOps has become the link between development and operations, allowing the development team to distribute products quickly.</p>
          `,
        },
        {
          image: '/images/services/cloud-devops-strategy-and-consulting.jpg',
          articleTitle: 'Cloud & DevOps Strategy and Consulting',
          articleText: `
            <ul>
              <li>Existing Infrastructure Audit (resources & costs)</li>
              <li>Technology, Processes and Resources Assessment</li>
              <li>Infrastructure Architecture Analysis (GCP, Azure, AWS, on-prem)</li>
              <li>Go-to-Cloud Roadmap</li>
              <li>Infrastructure Security and Compliance Check</li>
              <li>Cloud Maturity Assessment</li>
              <li>Cloud Automation Consulting</li>
              <li>Kubernetes and Containerization</li>
              <li>Application Modernization Strategy</li>
              <li>Workshops and Proofs of Concept</li>
            </ul>
          `,
        },
      ],
    },
    {
      icon: <FiCloud />,
      title: 'Cloud Services',
      shortText: `
        <ul>
          <li>Cloud Migration & Hosting</li>
          <li>Cloud Architecture Optimization</li>
          <li>Cloud Computing</li>
          <li>DevOps Services</li>
        <ul>`,
      route: 'cloud-services',
      overviewText:
        '<p>Our extensive knowledge in cloud security, cost management, and optimization can assist you in making the greatest use of cloud services. With our skills and connections spanning AWS, Microsoft Azure, and Google Cloud, we cover the entire cloud journey. Our culture is based on an Automation-First approach that emphasizes Infrastructure-as-Code and DevOps concepts in how we operate. Cloud migrations and hybrid solutions are among our skills, and we can assist you at all phases of migration and acceptance.</p>',
      // articles: [
      //   {
      //     image: '/images/industries/retail1.jpg',
      //     articleTitle: 'The vision',
      //     articleText:
      //       '<p>Lorem ipsum dolor consectetur adipiscing do eiusmod tempor incididunt labore.</p>',
      //   },
      // ],
    },
    {
      icon: <FiTool />,
      title: 'Quality Assurance',
      shortText: `
        <ul>
          <li>Automated Testing</li>
          <li>Performance Testing</li>
          <li>Independent Testing</li>
          <li>DevOps QA</li>
        <ul>`,
      route: 'quality-assurance',
      overviewText: `
        <p>Quality Assurance is a procedure used to ensure the quality of software products or services provided by an organization to its customers. Another way to think about quality assurance (QA) is as a company's method for enhancing the quality of its products.</p>
        <p>Hegecode is committed to improving the software development process and making it more efficient and effective in accordance with the quality standards established for software products.</p>
      `,
    },
    {
      icon: <FiEdit />,
      title: 'UI / UX Design',
      shortText: `
        <ul>
          <li>Graphical & Web Design</li>
          <li>Wireframing & Prototyping</li>
          <li>Corporate identity</li>
          <li>Usability Reviews & Audit</li>
        <ul>`,
      route: 'ui-ux-design',
      overviewText: `
        <p>The process of developing the interface, optimizing navigation, and displaying important elements of a product or service is known as UI/UX Design. It blends design and user experience elements to build interfaces that are simple to use, give what users need fast, look fantastic, are intuitive, and generate an overall good user experience.</p>
        <p>It also takes into account user preferences, perceptivity, and emotional quotient. A strong UX design improves usability, accessibility, and functionality while also allowing for enjoyable user interaction with your website or app.</p>
      `,
      articles: [
        {
          image: '/images/services/ui-ux-design.jpg',
          articleTitle: 'Importance of UI / UX Design',
          articleText: `
            <p>A solid UI / UX design will help you achieve better long-term outcomes. It might assist you in generating more leads and improving conversion rates. This results in more income for your firm.</p>
            <p>With a superb UI / UX design, you can effortlessly meet your clients' expectations, boost their readiness to spend by nearly 15%, and enhance brand loyalty by 16%.</p>
            <p>According to Forrester, a renowned worldwide market research firm, a fantastic website's User Interface may raise conversion rates by 200%, while a seamless UX can increase conversion rates by 300% - 400%. These figures are startling and have the potential to transform the life cycle of your customer's journey.</p>
          `,
        },
      ],
    },
    {
      icon: <FiEye />,
      title: 'Digital Marketing',
      shortText: `
        <ul>
          <li>SEO & Content</li>
          <li>Performance Marketing</li>
          <li>Social Media Strategy</li>
          <li>App & Web Analytics</li>
        <ul>`,
      route: 'digital-marketing',
      overviewText:
        '<p>Digital marketing, often known as online marketing, is the promotion of companies via the use of the internet and other types of digital communication to interact with potential clients. This comprises text and multimedia messaging as a marketing channel in addition to email, social media, and web-based advertising.</p>',
      articles: [
        {
          image: '/images/services/responsive-web-design.jpg',
          articleTitle: 'The importance of the Responsive Web Design',
          articleText: `
            <p>Internet activity is no longer limited to desktop computers. Today, your website may be shown on hundreds of different screen sizes. Hegecode develops websites that can automatically adjust to smaller or bigger screen sizes. Mobile web browsing surpassed desktop internet use in 2014. Don't lose consumers because your website is out of date; instead, let us design the website you want and need.</p>
          `,
        },
      ],
    },
  ],

  // Expertise page
  OUR_EXPERTISE_PRE_TITLE: 'EXPERTISE',
  OUR_EXPERTISE_TITLE: 'Our Expertise',
  OUR_EXPERTISE_TEXT:
    'Due to our extensive experience working with a variety of clients, we have a deep understanding of their demands, which enables us to quickly select the appropriate solution for any problem or project. We are able to select the best and quickest solutions to any problems a client might encounter because of our customer-focused approach.',
  SOFTWARE_SOLUTIONS_TITLE: 'Do you need software solutions for your business?',
  SOFTWARE_SOLUTIONS_SUBTITLE:
    'Please contact us and we will do our best to find a solution for you',
  SOFTWARE_SOLUTIONS_BUTTON: 'Contact Us',
  WHO_WE_ARE_QUESTION: 'Who we are?',
  WHO_WE_ARE_ANSWER:
    'We are an software company, doing our best to offer high standard services.',
  WHERE_ARE_WE_BASED_QUESTION: 'Where are we based?',
  WHERE_ARE_WE_BASED_ANSWER:
    'The company is headquartered at the north of Transylvania, in a small city, named Sighetu Marmației, which is the capital of the "Historical Maramureș" from Romania, but our engineers are located in different places around the world.',
  WHERE_ARE_OUR_ENGINEERS_BASED_QUESTION: 'Where are our engineers based?',
  WHERE_ARE_OUR_ENGINEERS_BASED_ANSWER:
    'We have engineers working from different places in the world, including Romania and the United States.',
  HOW_TO_CONTACT_QUESTION: 'How can you get in touch with us?',
  HOW_TO_CONTACT_ANSWER:
    'If you need our software solutions, please let us know. You can contact writing on email address contact@hegecode.com, or on our social profiles displayed at the bottom of the page.',
  OUR_VALUES_TITLE: 'Our Values.',
  OUR_VALUES_TITLE_WITHOUT_DOT: 'Our Values',
  OUR_VALUES_TEXT_1:
    'Our goal is to use technology in ways that inspire and enable people and organizations to realize their full potential.',
  OUR_VALUES_TEXT_2:
    'We are deeply committed to the success of our employees, our customers and their organizations, and the countries in which we operate. We have nothing to hide from our customers because we are confident in our abilities, approach, and people. We embrace change and value differences, which allows us to succeed in complex environments. We hire smart people who combine experience and talent to provide smarter solutions to our customers. Our relationships are based on trust and respect.',
  OUR_VALUES_TITLE_ENUMERATION: [
    {
      icon: <FiBarChart />,
      title: 'Innovation',
      text: 'The key to growth is innovation. We approach people, projects, and experiences with a pioneering mindset, seeking out new solutions that benefit our clients, team, and community.',
    },
    {
      icon: <FiStar />,
      title: 'Quality',
      text: 'We provide our services with the best interests of our clients in mind. The solutions we offer speak for themselves: they are timely and cost-effective, delivering the greatest impact in the shortest amount of time.',
    },
    {
      icon: <FiTool />,
      title: 'Adaptability',
      text: 'Hegecode is motivated by the desire to venture into uncharted territory. The unknown challenges us; we map it while applying our limitless creativity to new projects, skills, and initiatives.',
    },
    {
      icon: <FiBriefcase />,
      title: 'Trust',
      text: 'We believe that this is one of the most important values in maintaining long-term relationships, and we are working hard to instill this value in our clients.',
    },
    {
      icon: <FiShield />,
      title: 'Respect',
      text: "We make a living through technology, but we make a life by prioritizing people. Every member of our team, our clients' teams, and our community is valued, and we treat everyone with dignity and respect.",
    },
  ],
  INDUSTRIES_HEADER_TITLE:
    'Every industry is unique. Understanding the distinct challenges that each industry encounters is crucial to business success',
  INDUSTRIES_HEADER_TEXT:
    'You may engage with service providers who have a broad understanding of business and technology, or you could select a partner who is well-versed in your area. Capgemini has firsthand experience partnering with clients from all industries to help them achieve the future they desire for their company.',
  INDUSTRIES_ENUMERATION: [
    {
      icon: <FiShoppingBag />,
      title: 'Retail',
      shortText:
        'We collaborate with worldwide retailers to uncover new business opportunities and capitalize on the enormous potential of next-generation technologies.<br/><br/>',
      route: 'retail',
      overviewText:
        '<p>To thrive in an ever-changing environment, targeted strategies, operational excellence, and ongoing innovation are required. Customers want convenient, immersive, and unified purchase experiences, while organizations want to be responsive and regenerative. Retailers are considering a wide range of options. We collaborate with worldwide retailers to uncover new business opportunities and capitalize on the enormous potential of next-generation technologies.</p>',
      articles: [
        {
          image: '/images/industries/retail1.jpg',
          articleTitle: 'The vision',
          articleText:
            '<p>The retail environment is evolving. Industry experts have spent a significant amount of time debating what this means and how it will all play out in the next years. The question is, what do customers want to see? More significantly, what can we expect to see on the high street in the next ten years?</p><p>Change is being accelerated at an exponential rate by technology. Smaller retailers are expected to close, restricting customer alternatives and driving more people online, according to experts. After all, 2020 saw a global pandemic, a predominant economic downturn and a large wave of store closures and industry bankruptcies that defined much of the retail scene.</p>',
        },
        {
          image: '/images/industries/retail2.jpg',
          articleTitle: 'We are aware of the challenges faced by businesses.',
          articleText:
            '<p>Striving to build a compelling user experience but lacking the business agility to quickly react to their needs.</p><p>Having difficulty reaching their full business potential due to low customer confidence created by a bad digital user experience.</p><p>Looking to improve their client experience but are hampered by inefficient digital infrastructure and poor system integration.</p><p>In order to meet consumer demands and expectations, we strive to thoroughly understand and respond to customer needs across numerous environments.</p>',
        },
      ],
    },
    {
      icon: <FiInfo />,
      title: 'Technology',
      shortText:
        'Keeping up with emerging technologies is always tough, regardless of industry. We assist businesses in making the most of their potential and capabilities by integrating technological expertise with industry demands.',
      route: 'technology',
      overviewText:
        "<p>Our most important team quality is that we understand various business models and our partners' various objectives, and we work together to optimize and achieve those goals. We specialize in developing profitable custom software.</p>",
      articles: [
        {
          image: '/images/industries/technology.jpg',
          articleTitle: 'We are aware of the challenges faced by businesses',
          articleText:
            '<p>Because of their tech-savvy employees, you might expect technology and software companies to face fewer IT challenges than other businesses; however, this is not always the case. Because of the technical nature of their products and services, businesses in fintech, software, EDA, and similar fields frequently have high IT demands. As a result, they face additional challenges that non-tech firms may not.</p><p>Fortunately, the Hegecode team is on hand to assist. We can help your technology company get more out of its information systems as a managed IT services provider. We will equip you to overcome the IT challenges you face on a daily basis.</p>',
        },
      ],
    },
    {
      icon: <FiDollarSign />,
      title: 'Financial Services',
      shortText:
        'The financial services industry is rapidly adopting digital technology. Paying with cash, meeting with financial consultants in person, and even using an ATM are all fading aspects of financial services.<br/><br/>',
      route: 'financial-services',
      overviewText:
        '<p>Capitalization requirements imposed after 2007 will assist most banks in surviving the COVID-19 crisis. They will not, however, escape unscathed. In this volatile and unpredictable market, banks must concentrate on markets where they are best in class and withdraw from those where their business, technology, or people are not competitive.</p><p>The financial services industry accounts for at least 20% of the global economy, and its impact on economic growth is significant. The industry faced challenges in 2021 due to ultra-low interest rates and debt defaults, but as the pandemic recedes, most markets are expected to recover.</p>',
      articles: [
        {
          image: '/images/industries/financial-services.jpg',
          articleTitle: 'We are aware of the challenges faced by businesses',
          articleText:
            '<p>The challenges confronting the banking and financial services industries are multiplying at an alarming rate. Banking institutions are under increasing pressure to adapt and grow as a result of new technology, new competitors, and a shifting digital landscape. It is now more important than ever for your company to identify areas for improvement and come up with innovative solutions to achieve success.</p><p>Because the banking industry has traditionally taken a risk-averse approach, businesses may be hesitant to embark on large-scale digital transformation projects. This hesitation opens the market to new disruptive competitors with a flexible business model that can easily adopt new innovations. What is the solution? To innovate, concentrate on small areas of your business. Through a digital evolution methodology, you can embrace change without risk and scale your activity as you achieve results.</p>',
        },
      ],
    },
    {
      icon: <FiTool />,
      title: 'Automotive',
      shortText:
        'Customer behavior and expectations are changing, and new regulations, digitization, and societal pressure for sustainability have introduced a slew of new and, at times, competing priorities for all automotive players.',
      route: 'automotive',
      overviewText:
        "<p>It's one of the most exciting times in the automotive industry's history, as well as one of the most disruptive. Breakthroughs in electric vehicles, self-driving cars, ridesharing, and other areas are affecting more than just the industry. They have the potential to change the world.</p><p>Over the last century, vehicular mobility has been the engine that transforms society, but companies in the automotive industry still have a long way to go.</p>",
      articles: [
        {
          image: '/images/industries/automotive.jpg',
          articleTitle: 'We are aware of the challenges faced by businesses',
          articleText:
            "<p>The automotive industry is constantly changing, and businesses in this industry must keep up with the latest trends. Businesses must be able to adapt quickly to market changes in order to survive and thrive in today's competitive environment.</p><p>The current challenges in the automotive industry provide an opportunity for companies to rethink their strategies and develop new ways of doing business. By embracing change and innovation, the automotive industry can emerge from this crisis stronger than ever before.</p>",
        },
      ],
    },
    {
      icon: <FiMusic />,
      title: 'Events Services',
      shortText:
        'Through the power of events around the world, we are enriching lives and driving economic growth.</br></br></br></br>',
      route: 'events-services',
      overviewText:
        "<p>The event services market is expanding due to an increase in business activities such as conferences/seminars, brand promotions, employee training activities, development in the tourism and hospitality sectors, and expansion in IT hubs around the world. Other important factors driving market growth include an increase in business travel, a shift in business travelers' lifestyles to seek leisure trips, rapid urbanization, and an increase in disposable incomes. As a result, the increase in business activities fuels demand for event planners, personnel service providers, and furniture and equipment rental services. Furthermore, the deployment of best-in-class technology capable of handling the entire event drives industry growth. Furthermore, multi-dimensional events are a major driver of the event services market's expansion.</p>",
      articles: [
        {
          image: '/images/industries/events-services.jpg',
          articleTitle: 'We are aware of the challenges faced by businesses',
          articleText:
            '<p>Over the last few months, the COVID-19 outbreak has had a rapid but massive impact on many industries; virtually no one has been left unaffected in some way. Those in the event industry, on the other hand, have been particularly hard hit. In the face of a global crisis in which physical gathering is the most important thing to avoid, nearly all businesses are left looking around in panic, wondering, "What do we do now?".</p><p>A global pandemic that halts life as we know it is undoubtedly an unprecedented contender in the list of problems that an event organizer may face on a daily basis. Despite the harsh challenge, it is inspiring to see businesses put their best foot forward and not only adapt to their current situation, but also position themselves to emerge stronger on the other side.</p>',
        },
      ],
    },
    {
      icon: <FiTrendingUp />,
      title: 'Advertising Services',
      shortText:
        'The advertising industry is critical to business marketing strategies. Advertising has evolved into an important strategic tool for businesses seeking to differentiate themselves or position themselves in the market.',
      route: 'advertising-services',
      overviewText:
        '<p>The advertising industry is critical to business marketing strategies. Advertising has evolved into an important strategic tool for businesses seeking to differentiate themselves or position themselves in the market. Given the increased competition among producers/providers of goods and services, businesses are increasingly appreciating the value of advertising.</p><p>Marketers must measure their return on advertising investment and optimize their marketing mix, while publishers and platforms must demonstrate and quantify their unique contributions in order to attract advertising spend.</p><p>The rise of data-driven digital advertising channels has raised expectations for actionable analysis across all channels. This presents a significant challenge to publishers and platforms that do not currently have comparable capabilities for capturing, analyzing, and quantifying advertising impact.</p>',
      articles: [
        {
          image: '/images/industries/Untitled.jpg',
          articleTitle: 'We are aware of the challenges faced by businesses',
          articleText:
            "<p>As the retail advertising industry evolves due to technological advancement, new challenges emerge. With this in mind, and given the overall COVID 19 global scenario, it is unsurprising that maintaining retail advertising in a digital presence has become a must-have for successful retailers during the pandemic.</p><p>The decline in brick-and-mortar store shopping has resulted in an increase in online purchases and the proliferation of e-commerce platforms. As a result, we've seen many businesses assess their current digital experiences and refocus their efforts on this expanding market. This is where digital advertising stands out as a critical strategy for increasing awareness, driving more traffic, and converting visitors into customers, which may explain its rapid growth in recent years. This is where digital advertising stands out as a critical strategy for increasing awareness, driving more traffic, and converting visitors into customers, which may explain its rapid growth over the last two years.</p>",
        },
      ],
    },
    {
      icon: <FiShare2 />,
      title: 'Non-profit organisations',
      shortText:
        "Despite the decade of experience we have as a team, we understand how difficult a fresh start can be! So we've decided to give back to those who believe they can change the world by putting technology to work!",
      route: 'non-profit-organisations',
      overviewText:
        '<p>Despite the decade of experience we have as a team, we understand how difficult a fresh start can be! So we\'ve decided to give back to those who believe they can change the world by putting technology to work!</p><p>More donors, sponsors, and volunteers are turning to technology to help them take action, connect with others, and raise awareness in their communities. Financial reporting, sustainability planning, and performance measurement are all non-profit "back-office" functions that rely on IT capabilities. Non-profits are now noticing a close relationship between their IT infrastructure and their ability to carry out their mission.</p>',
      articles: [
        {
          image: '/images/industries/non-profit-organisations.jpg',
          articleTitle: 'We are aware of the challenges faced by businesses',
          articleText:
            '<p>Most non-profits face similar IT challenges:</p><ul><li>Finance: Non-profits "struggle" with IT budgeting, whether due to real cash flow issues or because every dollar spent here is one less dollar that can be dedicated to their mission.</li><li>People: It makes no sense for non-profits to maintain in-house deep technical expertise. Outsourcing IT functions and/or staff augmentation enables non-profits to devote more resources to their mission while providing IT support and expertise to their staff.</li><li>Mobile: You need to be able to swiftly capture donations and donor information. At donor events, having the appropriate mobile technology tools can support both of these. Your donor apps—are they mobile-ready? Your website is it mobile-friendly? Is everything safe?</li></ul><p>We assume responsibility and accountability for managing your entire IT environment in order to provide the performance and security that your board members and donors expect. Spending less time and money on technology allows your organization to devote more time and resources to interacting with sponsors, volunteers, and donors—who, as a community, can drive your mission.</p>',
        },
      ],
    },
  ],
});

export default locales;
