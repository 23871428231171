import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../breadcrumb/BreadcrumbOne';
import CustomIndustrySplit from '../split/CustomIndustrySplit';
import ScrollAnimation from 'react-animate-on-scroll';
import ClientsBrand from '../brand/ClientsBrand';

const CustomServices = ({ data }) => {
  const { overviewText, articles } = data;
  return (
    <>
      <SEO title={`${data.title} | Services`} />
      <Layout>
        <BreadcrumbOne
          title={data.title}
          rootUrl="/"
          parentUrl="Home"
          currentUrl={data.title}
        />
        <div className="main-content">
          <div className="rn-service-area rn-section-gap">
            <div className="container mb--100">
              <div className="row">
                <div className="col-lg-12">
                  <ScrollAnimation
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}
                  >
                    <h2
                      className="title w-600 mb--20"
                      dangerouslySetInnerHTML={{ __html: 'Overview' }}
                    ></h2>
                  </ScrollAnimation>
                  <ScrollAnimation
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}
                  >
                    <p
                      className="description b1"
                      dangerouslySetInnerHTML={{ __html: overviewText }}
                    ></p>
                  </ScrollAnimation>
                </div>
              </div>
            </div>
            {articles
              ? articles.map(({ articleTitle, articleText, image }, i) =>
                  image && articleText ? (
                    <CustomIndustrySplit
                      key={i}
                      image={image}
                      title={articleTitle}
                      text={articleText}
                      imageOnLeft={!(i % 2)}
                    />
                  ) : null
                )
              : null}
          </div>
          <div className="rwt-brand-area rn-section-gap">
            <ClientsBrand brandStyle="brand-style-2 justify-content-evenly" />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default CustomServices;
