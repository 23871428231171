import React from 'react';
import Typed from 'react-typed';
import SEO from '../common/SEO';
import { Link } from 'react-router-dom';
import { FiArrowRight } from 'react-icons/fi';
import AboutOne from '../elements/about/AboutOne';
import CalltoActionFive from '../elements/calltoaction/CalltoActionFive';
import SectionTitle from '../elements/sectionTitle/SectionTitle';
import Separator from '../elements/separator/Separator';
import OurValues from '../elements/about/OurValues';
import Mission from '../elements/about/Mission';
import locales from '../locales/locales';
import Layout from '../common/Layout';

const BusinessConsulting2 = () => {
  const {
    PRE_TITLE,
    TITLE,
    TITLE_ENUMERARION,
    SUBTITLE,
    SERVICES_PRE_TITLE,
    SERVICES_TITLE,
    SERVICES_TEXT,
  } = locales;

  return (
    <>
      <SEO title="Home" />
      <main className="page-wrapper">
        <Layout>
          {/* Start Slider Area  */}
          <div className="slider-area slider-style-1 bg-transparent height-850" style={{height:'100vh'}}>
            <div className="container" style={{ marginTop: '80px' }}>
              <div className="row">
                <div className="col-lg-12">
                  <div className="inner text-center">
                    <span className="subtitle">{PRE_TITLE}</span>
                    <h1 className="title theme-gradient-non-capitlaize display-two">
                      {TITLE} <br />{' '}
                      <Typed
                        style={{whiteSpace: 'nowrap', maxWidth: '95vw'}}
                        strings={TITLE_ENUMERARION}
                        typeSpeed={80}
                        backSpeed={5}
                        backDelay={1000}
                        loop
                      />
                    </h1>
                    <p className="description">{SUBTITLE}</p>
                    <div className="button-group">
                      <Link
                        className="btn-default btn-medium btn-border round btn-icon"
                        to="/contact"
                      >
                        Contact Us{' '}
                        <i className="icon">
                          <FiArrowRight />
                        </i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Slider Area  */}

          <Separator />

          {/* Start About Area  */}
          <AboutOne />
          {/* End About Area  */}

          <OurValues />

          {/* Start Service Area  */}
          <div className="rn-service-area rn-section-gap">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <SectionTitle
                    textAlign="text-center"
                    radiusRounded=""
                    subtitle={SERVICES_PRE_TITLE}
                    title={SERVICES_TITLE}
                    description={SERVICES_TEXT}
                  />
                </div>
              </div>
              {/* <ServiceFive serviceStyle='gallery-style' textAlign='text-start' /> */}
              <div
                className="button-group"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Link
                  className="btn-default btn-medium btn-border round btn-icon"
                  to="/services"
                >
                  Our Services{' '}
                  <i className="icon">
                    <FiArrowRight />
                  </i>
                </Link>
              </div>
            </div>
          </div>
          {/* End Service Area  */}

          {/* Start Call To Action Area  */}
          <div className="rwt-callto-action-area">
            <div className="wrapper">
              <CalltoActionFive />
            </div>
          </div>
          {/* End Call To Action Area  */}

          {/* Start Team Area  */}
          {/* <div className='rwt-team-area rn-section-gap'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <SectionTitle
                  textAlign='text-center'
                  radiusRounded=''
                  subtitle='Our Experts.'
                  title='Our Company Experts.'
                  description='We provide company and finance service for <br /> startups and company business.'
                />
              </div>
            </div>
            <TeamTwo
              column='col-lg-4 col-md-6 col-12'
              teamStyle='team-style-default style-two'
            />
          </div>
        </div> */}
          {/* End Team Area  */}
          {/* <Separator /> */}
          {/* Start Mission Area   */}
          <Mission />
          {/* Start Mission Area  */}

          {/* <Separator /> */}
          {/* Start Testimonial Area  */}
          {/* <div className='rwt-testimonial-area rn-section-gap'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <SectionTitle
                  textAlign='text-center'
                  radiusRounded=''
                  subtitle='Client Feedback'
                  title='Our Awesome Client.'
                  description='We provide company and finance service for <br /> startups and company business.'
                />
              </div>
            </div>
            <TestimonialThree teamStyle='' />
          </div>
        </div> */}
          {/* End Testimonial Area  */}

          {/* <Separator /> */}
          {/* <div className='blog-area rn-section-gap'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <SectionTitle
                  textAlign='text-center'
                  radiusRounded=''
                  subtitle='Latests News'
                  title='Our Latest News.'
                  description='We provide company and finance service for <br /> startups and company business.'
                />
              </div>
            </div>
            <div className='row row--15'>
              {BlogListData.map((item) => (
                <div key={item.id} className='col-lg-4 col-md-6 col-12 mt--30'>
                  <BlogList StyleVar='box-card-style-default' data={item} />
                </div>
              ))}
            </div>
          </div>
        </div> */}
        </Layout>
      </main>
    </>
  );
};
export default BusinessConsulting2;
