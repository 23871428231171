import React from 'react';

const BrandList = [
  {
    image: '/images/brand/brand-01.png',
  },
  {
    image: '/images/brand/brand-02.png',
  },
  {
    image: '/images/brand/brand-03.png',
  },
  {
    image: '/images/brand/brand-04.png',
  },
  {
    image: '/images/brand/brand-05.png',
  },
  {
    image: '/images/brand/brand-06.png',
  },
];

const ClientsBrand = ({ brandStyle }) => {
  return (
    <ul className={`brand-list ${brandStyle}`}>
      {BrandList.map((data, index) => (
        <li key={index}>
          {/* <a href="#undefined"> */}
          {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
          <img
            src={`${data.image}`}
            alt="Brand Image"
            style={{ maxWidth: '200px', minWidth: '150px' }}
          />
          {/* </a> */}
        </li>
      ))}
    </ul>
  );
};

export default ClientsBrand;
