import React from 'react';
import Typed from 'react-typed';
import { Link } from 'react-router-dom';
import { FiArrowRight } from 'react-icons/fi';
import locales from '../../locales/locales';
import OurValuesAccordion from '../accordion/OurValuesAccordion';

const OurValues = () => {
    const {
        OUR_VALUES_TITLE,
        OUR_VALUES_TITLE_ENUMERATION,
        OUR_VALUES_TEXT_1,
        OUR_VALUES_TEXT_2,
    } = locales;

    return (
        <div className="rn-company-mission-are rn-section-gap">
            <div className="container">
                <div className="row row--30">
                    <div className="col-lg-6">
                        <div className="mission-title">
                            <h2 className="title">
                                {OUR_VALUES_TITLE} <br />{' '}
                                <Typed
                                    className="theme-gradient"
                                    strings={OUR_VALUES_TITLE_ENUMERATION.map(
                                        (item) => `${item.title}.`
                                    )}
                                    typeSpeed={80}
                                    backSpeed={5}
                                    backDelay={1000}
                                    loop
                                />
                            </h2>

                            <p>{OUR_VALUES_TEXT_1}</p>
                            <p>{OUR_VALUES_TEXT_2}</p>

                            <div className="read-more-btn mt--50">
                                <Link
                                    className="btn-default btn-icon"
                                    to="/services"
                                >
                                    Find more about our services{' '}
                                    <i className="icon">
                                        <FiArrowRight />
                                    </i>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 mt_md--30 mt_sm--30">
                        <OurValuesAccordion customStyle="" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OurValues;
