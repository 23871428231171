import React from 'react';
import Typed from 'react-typed';
import { Link } from 'react-router-dom';
import { FiArrowRight } from 'react-icons/fi';
import QuestionsAccordion from '../accordion/QuestionsAccordion';
import locales from '../../locales/locales';

const mission = () => {
  const { TITLE, TITLE_ENUMERARION, OUR_EXPERTISE_TEXT } = locales;

  return (
    <div className="rn-company-mission-are rn-section-gap">
      <div className="container">
        <div className="row row--30">
          <div className="col-lg-6 mt_md--30 mt_sm--30 mb_md--30 mb_sm--30">
            <QuestionsAccordion customStyle="" />
          </div>
          <div className="col-lg-6">
            <div className="mission-title">
              <h2 className="title">
                {TITLE} <br />{' '}
                <Typed
                  className="theme-gradient"
                  strings={TITLE_ENUMERARION}
                  typeSpeed={80}
                  backSpeed={5}
                  backDelay={1000}
                  loop
                />
              </h2>

              <p>{OUR_EXPERTISE_TEXT}</p>

              <div className="read-more-btn mt--50">
                <Link className="btn-default btn-icon" to="/about">
                  Find more about us{' '}
                  <i className="icon">
                    <FiArrowRight />
                  </i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default mission;
