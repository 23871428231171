import contactData from './contactData';

const privacyPolicyTexts = Object.seal(`
<h4>Who we are?</h4>
<p>${contactData.companyName} is a Romanian legal entity, headquartered in ${contactData.address.city}, ${contactData.address.street}, having the unique registration code ${contactData.registrationCode}, registered with Bucharest Trade Register under no. ${contactData.tradeRegister}, hereinafter referred to as “The Company” or “Us”). Related to data protection and privacy, The Company is regarded as the data Operator regarding personal data which is collected and processed via the web application ${contactData.website} (hereinafter referred to as “The Website”).</p>

<p>The Company collects and processes as Operator personal data belonging to users who are interested in contacting Us in order to receive information and ultimately to buy services we offer. The users agree to submit their data to us via The Website or via email at ${contactData.contactEmail}.</p>

<p>This Privacy Policy is set to determine what happens to personal data belonging to the users contacting Us via The Website or via email at ${contactData.contactEmail}.</p>

<p>Also you can access here the ${contactData.companyName} Customer Information Notice regarding the processing of personal data, respectively the ${contactData.companyName} Supplier Information Notice regarding the processing of personal data.</p>

<p>Below is the Index of this Privacy Policy. For further details regarding processing of personal data performed by The Company, please follow individual links comprised in each section of this Index, in order to access the entire content of the Privacy Policy related to each subject.</p>

Index:

<ul>
  <li>What types of personal data does the Company collect?</li>
  <li>How do we collect your personal data?</li>
  <li>Purpose of personal data processing</li>
  <li>Basis for personal data processing</li>
  <li>Disclosure of personal data</li>
  <li>Transfer of personal data to countries outside the European Economic Area</li>
  <li>Storing personal data</li>
  <li>Personal data safety</li>
  <li>Your rights regarding own personal data</li>
  <li>What happens if you don’t want to submit your personal data?</li>
  <li>Reliability regarding data protection|</li>
</ul>

<h4>1. What types of personal data does The Company collect?</h4>
<p>1.1. The personal data that The Company collects via The Website consist of: your name, surname, email address, phone.</p>

<h4>2. How do we collect your personal data?</h4>
2.1. The means of collecting personal data in regards to your interest about Hegecode consist of:

<ul>
  <li>By filling the fields required in the contact forms of The Website</li>
  <li>By receiving an email from you addressed to ${contactData.contactEmail}</li>
</ul>

<h4>3. Purpose of personal data processing</h4>
<p>3.1.The Company is processing personal data belonging to interested Users in order to cater to their interest in gathering details about the services prior to purchasing Hegecode services.</p>

<h4>4. Basis for personal data processing</h4>
4.1. The Company uses personal data belonging to Users for the following actions:

Sending notifications;

<ul>
  <li>Contacting Users for arranging a meeting;</li>
  <li>Asking Users for more details if necessary;</li>
  <li>Sharing news related to Hegecode with the Users.</li>
</ul>

<p>4.2. The following activities are done in regards to personal data belonging to Users: data collection, data storage, data consulting, data analysis, data modification.</p>

<h4>5. Disclosure of personal data</h4>
5.1. The Company maintains the right to disclose Users’ personal data only to trusted third parties, which may be from these categories:

<ul>
  <li>Our agents and/or service providers which work with The Company to provide technical services like data analysis, hosting or technical assistance;</li>
  <li>Our professional consultants and/or auditors;</li>
  <li>Courts of Law, Regulatory Authorities, Public Administration Entities and Law Enforcement Authorities from Romania or abroad</li>
</ul>

<p>5.2. For a detailed list of third parties to which The Company may disclose personal data belonging to Users interested in Hegecode, please contact us for a free-of-charge copy of the list, via email directed to ${contactData.privacyEmail}.</p>

<h4>6. Transfer of personal data to countries outside the European Economic Area</h4>
<p>6.1. Personal data belonging to Users may be transferred to countries outside the European Economic Area in order to store these data in the cloud with our providers, one of which is located in the US. In this case, prior to transferring data, we have taken precautionary measures for ensuring an adequate storage facilities, according to the data protection legislation.</p>

<h4>7. Storing personal data</h4>
7.1. As a general rule, The Company is storing Users’ personal data as long as it is required, considering the following:

<ul>
  <li>While unfolding the process of reaching an agreement towards signing a Commercial Contract;</li>
  <li>In order to cater to the Rule of Law;</li>
  <li>In the context of an Act of Law or Investigation to which The Company may be subject to.</li>
</ul>

<p>7.2. After the beforehand mentioned period stated at 7.1 has expired, we will store Personal Data belonging to Users for a period of 5 (five) years.</p>

<h4>8. Personal data safety</h4>
<p>8.1. The Company deploys a wide array of measures and technical safety features in order to protect Users’ data against unwanted access, usage, disclosure or modification, according to the Rule of Law regarding data protection.</p>

<p>8.2. For instance, when disclosing your personal data to third parties as mentioned beforehand at 5, we ask for a written accord which enforces providers to maintain data privacy and to instill adequate safety measures in order to guarantee the safety of your personal data.</p>

<h4>9. Your rights regarding own personal data</h4>
9.1. As the owner of your own personal data, you are entitled to:

<ul>
  <li>Contact the Company in order to gain access to information about processing your personal data, including asking for a copy of your personal data which The Company has come in contact with;</li>
  <li>Ask for updating and/or deleting your personal data;</li>
  <li>Ask for a restricted handling of your personal data or opt against data processing;</li>
  <li>Ask for transfer of personal data belonging to you and which you have provided The Company with, to a different organization in electronic format;</li>
  <li>File a complaint to the Autoritatea Națională de Supraveghere a Prelucrării Datelor cu Caracter Personal, headquartered in B-dul G-ral. Gheorghe Magheru 28-30, Sector 1, 010336, Bucuresti, Romania, email address: anspdcp@dataprotection.ro, if you feel like your rights regarding your personal data have been infringed.</li>
</ul>

<p>9.2. If you wish to exercise these rights, please communicate this with The Company, via email to ${contactData.privacyEmail}.</p>

<h4>10. What happens if you don’t want to submit your personal data?</h4>
<p>10.1. Should you choose not to agree to submitting your personal data to us or feel in disagreement with presented means of data processing, we will respect this decision as stated by the Rule of Law. Consequently, this means that we will not be able to perform the activities required and described hereinbefore considering your interaction with the Website.</p>

<p>10.2. The Company may be forced or entitled to keep your personal data in order to obey the Rule of Law or Regulatory Authorities in order to preserve its own legal interest or rights.</p>

<h4>11. Reliability regarding data protection</h4>
<p>11.1. Should you have any additional questions or requests regarding processing of your personal data or should your require additional information, please contact our designated Data Protection Officer by email at ${contactData.privacyEmail}</p>
`);

export default privacyPolicyTexts;
