import React from 'react';
import locales from '../../locales/locales';
import SectionTitle from '../sectionTitle/SectionTitle';
import OurValuesScrollAnimation from '../service/OurValuesScrollAnimation';

const AboutOurValues = () => {
  const { OUR_VALUES_TITLE_WITHOUT_DOT, OUR_VALUES_TEXT_1, OUR_VALUES_TEXT_2 } =
    locales;

  return (
    <div className="rn-service-area rn-section-gap">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <SectionTitle
              textAlign="text-center"
              radiusRounded=""
              subtitle={OUR_VALUES_TITLE_WITHOUT_DOT}
              title={OUR_VALUES_TEXT_1}
              description={OUR_VALUES_TEXT_2}
            />
          </div>
        </div>
        <OurValuesScrollAnimation
          serviceStyle="service__style--1 icon-circle-style"
          textAlign="text-center"
        />
      </div>
    </div>
  );
};

export default AboutOurValues;
