import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { FiArrowRight } from 'react-icons/fi';
import locales from '../../locales/locales';

const CalltoActionFive = () => {
    return (
        <div className="rn-callto-action clltoaction-style-default style-5">
            <div className="container">
                <div className="row row--0 align-items-center content-wrapper theme-shape">
                    <div className="col-lg-12">
                        <div className="inner">
                            <div className="content text-center">
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}
                                >
                                    <h2 className="title">
                                        {locales.SOFTWARE_SOLUTIONS_TITLE}
                                    </h2>
                                </ScrollAnimation>
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}
                                >
                                    <h6 className="subtitle">
                                        {locales.SOFTWARE_SOLUTIONS_SUBTITLE}
                                    </h6>
                                </ScrollAnimation>

                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}
                                >
                                    <div className="call-to-btn text-center">
                                        <a
                                            className="btn-default btn-icon"
                                            href="/contact"
                                        >
                                            {locales.SOFTWARE_SOLUTIONS_BUTTON}{' '}
                                            <i className="icon">
                                                <FiArrowRight />
                                            </i>
                                        </a>
                                    </div>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default CalltoActionFive;
