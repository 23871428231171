import React from 'react';
import { Link } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';
import locales from '../../locales/locales';

const ServicesScrollAnimation = ({ textAlign, serviceStyle }) => {
  return (
    <div className="row row--15 service-wrapper">
      {locales.SERVICES_ENUMERATION.map(
        ({ icon, title, shortText, route }, i) => (
          <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
            <ScrollAnimation
              animateIn="fadeInUp"
              animateOut="fadeInOut"
              animateOnce={true}
            >
              <div className={`service ${serviceStyle} ${textAlign}`}>
                <div className="icon">{icon}</div>
                <div className="content">
                  <h4 className="title w-600">
                    <Link
                      to={`/services/${route}`}
                      dangerouslySetInnerHTML={{ __html: title }}
                    ></Link>
                  </h4>
                  <p
                    className="description b1 color-gray mb--0"
                    dangerouslySetInnerHTML={{ __html: shortText }}
                  ></p>
                </div>
                <div className="button-group mt--20">
                  <Link
                    className="btn-default btn-small btn-border round btn-icon"
                    to={`/services/${route}`}
                  >
                    Find out more
                  </Link>
                </div>
              </div>
            </ScrollAnimation>
          </div>
        )
      )}
    </div>
  );
};

export default ServicesScrollAnimation;
