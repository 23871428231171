const contactData = Object.seal({
  email: 'contact@hegecode.com',
  phoneNumber: '(+40) 743 653 979',
  companyName: "HEGECODE SOFTWARE SRL",
  registrationCode: "RO43406070",
  website: "https://hegecode.com",
  contactEmail: "contact@hegecode.com",
  privacyEmail: "privacy@hegecode.com",
  tradeRegister: "J24/1493/2020",
  address: {
    street: '33 Plevnei, 400397',
    city: 'Sighetu Marmației, România',
    nameOnMap: 'Hegecode Headquarters',
    coordinates: {
      latitude: 47.934005,
      longitude: 23.888201,
    },
  },
});

export default contactData;
