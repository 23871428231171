import React from 'react';
import { Accordion, Card, Button } from 'react-bootstrap';
import locales from '../../locales/locales';

const OurValuesAccordion = ({ customStyle }) => {
    const { OUR_VALUES_TITLE_ENUMERATION } = locales;
    return (
        <Accordion
            className={`rn-accordion-style ${customStyle}`}
            defaultActiveKey="0"
        >
            {OUR_VALUES_TITLE_ENUMERATION.map(({ title, text }, index) => (
                <Card>
                    <Card.Header>
                        <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey={`${index}`}
                        >
                            {title}
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey={`${index}`}>
                        <Card.Body>{text}</Card.Body>
                    </Accordion.Collapse>
                </Card>
            ))}
        </Accordion>
    );
};

export default OurValuesAccordion;
