import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../breadcrumb/BreadcrumbOne';
import ContactOne from './ContactOne';

const Contact = () => {
  return (
    <>
      <SEO title="Contact Us" />
      <Layout>
        <BreadcrumbOne
          title="We’d love to discuss your ideas. <br /> Get in touch with us. Your way."
          rootUrl="/"
          parentUrl="Home"
          currentUrl="Contact"
        />
        <div className="main-content">
          {/* Start Contact Area  */}
          <div className="rwt-contact-area rn-section-gap">
            <div className="container">
              <ContactOne />
            </div>
          </div>
          {/* End Contact Area  */}
        </div>
      </Layout>
    </>
  );
};
export default Contact;
