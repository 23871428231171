import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

const CustomIndustrySplit = ({ image, title, text, imageOnLeft }) => {
  return (
    <div className="rn-splite-style mb--40">
      <div className="split-wrapper">
        <div className="row no-gutters radius-10 align-items-center">
          {imageOnLeft ? (
            <div className="col-lg-12 col-xl-6 col-12">
              <div className="thumbnail image-left-content">
                <img src={image} alt="Split Images" />
              </div>
            </div>
          ) : null}
          <div className="col-lg-12 col-xl-6 col-12">
            <div className="split-inner">
              {title ? (
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  animateOnce={true}
                >
                  <h4 className="title">{title}</h4>
                </ScrollAnimation>
              ) : null}
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <div dangerouslySetInnerHTML={{ __html: text }} />
              </ScrollAnimation>
            </div>
          </div>

          {!imageOnLeft ? (
            <div className="col-lg-12 col-xl-6 col-12">
              <div className="thumbnail image-left-content">
                <img src={image} alt="Split Images" />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default CustomIndustrySplit;
