import React from 'react';
import SEO from '../common/SEO';
import Separator from '../elements/separator/Separator';
import locales from '../locales/locales';
import AboutOurValues from '../elements/about/AboutOurValues';
import Layout from '../common/Layout';

const AboutUs = () => {
  const {
    PRE_TITLE,
    TITLE,
    ABOUT_TITLE,
    ABOUT_HEADER_LEFT,
    ABOUT_HEADER_RIGHT,
  } = locales;
  return (
    <>
      <SEO title="About Us" />
      <main className="page-wrapper">
        <Layout>
          {/* Start Slider Area  */}
          <div
            className="slider-area slider-style-1 height-850 bg_image"
            data-black-overlay="7"
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-12.jpg)`,
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="inner pt--80 text-center">
                    <div>
                      <h3 className="rn-sub-badge">
                        <span className="theme-gradient">{PRE_TITLE}</span>
                      </h3>
                    </div>
                    <h1 className="title display-one">{ABOUT_TITLE}</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Slider Area  */}

          {/* Start Service Area  */}
          <div className="service-area rn-section-gapTop">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="content">
                    <h3 className="title">{TITLE}</h3>
                  </div>
                </div>
                <div className="col-lg-6">
                  <p>
                    {ABOUT_HEADER_LEFT}
                  </p>
                  <p className="mb--10">{ABOUT_HEADER_RIGHT}</p>
                </div>
              </div>
            </div>
          </div>
          {/* End Service Area  */}

          {/* Start Brand Area  */}
          {/* <div className="rwt-brand-area pb--60 pt--30">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 mt--10">
                <BrandThree brandStyle="brand-style-2" />
              </div>
            </div>
          </div>
        </div> */}
          {/* End Brand Area  */}

          <Separator />

          <AboutOurValues />

          {/* <div className="rwt-timeline-area rn-section-gapBottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <TimelineTwo classVar="no-gradient"  />
                            </div>
                        </div>
                    </div>
                </div> */}

          {/* <Separator /> */}

          {/* <AboutFour image="./images/about/contact-image.jpg" /> */}

          {/* Start Elements Area  */}
          {/* <div className="rwt-counterup-area pb--100">
                    <div className="container mt_dec--30">
                        <CounterUpFour column="col-lg-3 col-md-6 col-sm-6 col-12" counterStyle="counter-style-4" textALign="text-center" />   
                    </div>
                </div> */}
          {/* End Elements Area  */}

          {/* <Separator /> */}

          {/* Start Elements Area  */}
          {/* <div className="rwt-team-area rn-section-gap">
                    <div className="container">
                        <div className="row mb--20">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Our Corporate Team."
                                    title = "Corporate Team Member."
                                    description = ""
                                />
                            </div>
                        </div>
                        <TeamFour column="col-lg-6 col-xl-3 col-md-6 col-12 mt--30" teamStyle="team-style-three" />
                    </div>
                </div> */}
          {/* End Elements Area  */}
        </Layout>
      </main>
    </>
  );
};

export default AboutUs;
