import React from 'react';
import { Accordion, Card, Button } from 'react-bootstrap';
import locales from '../../locales/locales';

const QuestionsAccordion = ({ customStyle }) => {
    const {
        WHO_WE_ARE_QUESTION,
        WHO_WE_ARE_ANSWER,
        WHERE_ARE_WE_BASED_QUESTION,
        WHERE_ARE_WE_BASED_ANSWER,
        WHERE_ARE_OUR_ENGINEERS_BASED_QUESTION,
        WHERE_ARE_OUR_ENGINEERS_BASED_ANSWER,
        HOW_TO_CONTACT_QUESTION,
        HOW_TO_CONTACT_ANSWER,
    } = locales;
    return (
        <Accordion
            className={`rn-accordion-style ${customStyle}`}
            defaultActiveKey="0"
        >
            <Card>
                <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                        {WHO_WE_ARE_QUESTION}
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>{WHO_WE_ARE_ANSWER}</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="1">
                        {WHERE_ARE_WE_BASED_QUESTION}
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                    <Card.Body>{WHERE_ARE_WE_BASED_ANSWER}</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="3">
                        {WHERE_ARE_OUR_ENGINEERS_BASED_QUESTION}
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                    <Card.Body>
                        {WHERE_ARE_OUR_ENGINEERS_BASED_ANSWER}
                    </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="2">
                        {HOW_TO_CONTACT_QUESTION}
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                    <Card.Body>{HOW_TO_CONTACT_ANSWER}</Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    );
};

export default QuestionsAccordion;
