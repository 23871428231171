import React from 'react';
import { Link } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';
import locales from '../../locales/locales';

const OurValuesScrollAnimation = ({ textAlign, serviceStyle }) => {
  return (
    <div className="row row--15 service-wrapper">
      {locales.OUR_VALUES_TITLE_ENUMERATION.map(({ icon, title, text }, i) => (
        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
          <ScrollAnimation
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}
          >
            <div className={`service ${serviceStyle} ${textAlign}`}>
              <div className="icon">{icon}</div>
              <div className="content">
                <h4 className="title w-600">
                  <Link
                    to="#"
                    dangerouslySetInnerHTML={{ __html: title }}
                  ></Link>
                </h4>
                <p
                  className="description b1 color-gray mb--0"
                  dangerouslySetInnerHTML={{ __html: text }}
                ></p>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      ))}
    </div>
  );
};

export default OurValuesScrollAnimation;
